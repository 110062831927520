import ApiService from "@/services/api_service"
import {IApiResponse, IData, IStep} from "@/types"
import qs from "qs"

export default {

  getSteps(flow: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {

      const actionName = 'get_steps'
      const params: IData = {
        action: actionName,
        flow,
      }
      ApiService.request({
        method: "get",
        params
      }).then((data) => {
        console.log('StepsService getSteps success', data)
        resolve(data)
      }).catch((data) => {
        console.log('StepsService getSteps error', data)
        reject(data)
      })

    })
  },

  saveWorkflow: function (attrs): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        ...attrs,
        action: "save_workflow",
        tab: 'workflow',
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },


  /*
    get_steps: function (projectId: number): Promise<any> {
      return new Promise((resolve, reject)=>{
        const params = {
          action: 'manage_project',
          type: 'json',
          site_ver: 'v2',
          project: 59,
          tab: 'workflow'
        }
        ApiService._axios({
          url: '/action.php',
          method: "post",
          params: params
        }).then(response => {
          if (response.data.isError) {
            throw new Error(response.data.message);
          } else {
            resolve(response?.data?.xdata?.steps)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    remove_step: function (stepId: number): Promise<any> {
      return new Promise(()=>{
        console.log('');
      });
    },

    save_steps: function (steps: IStep[]): Promise<any> {
      return new Promise((resolve, reject)=>{
        console.log('save_workflow');

        const _s = new Map();
        const _n = <any>[];
        steps.forEach((v, index)=>{
          if (v.id) {
            _s.set(v.id, v)
          } else {
            _n.push(v);
          }
        });
        const params = {
          action: 'save_workflow',
          type: 'json',
          site_ver: 'v2',
          project_id: 59,
          tab: 'workflow',
          steps: Object.fromEntries(_s.entries()),
          newsteps: _n
        }

        console.log('------', Object.fromEntries(_s.entries()));
        ApiService._axios({
          url: '/action.php',
          method: "post",
          headers: {
            // 'Content-Type': "application/json"
            'Content-Type': "application/x-www-form-urlencoded"
          },
          data: qs.stringify(params),
          // paramsSerializer: params => {
          //   return qs.stringify(params)
          // }
        }).then(response => {
          if (response.data.isError) {
            throw new Error(response.data.message);
          } else {
            resolve(response?.data?.xdata?.steps)
          }
        }).catch(error => {
          reject(error)
        })
      });
    }
  */
}

